import Answer from './Answer'
import { nanoid } from 'nanoid'

export default function Questions(props) {
  
  const answers =  props.allAnswers.map((answer, index) => {
      return <Answer 
      questionId={props.questionId}
      answer={answer}
      answerId={nanoid()}
      key={index}
      //select answer function
      SelectAnswer={props.SelectAnswer}
      selectedAnswer={props.selectedAnswer}
      correctAnswer={props.correctAnswer}
      checked={props.checked}
      
        />
  });


  return (
    <div className="question">
      <h2 dangerouslySetInnerHTML={{ __html: props.question }}></h2>
      {answers}
    </div>
  );
}
