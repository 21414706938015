import React from 'react'
import Questions from './Questions'

export default function Quiz(props){  
   
  
        const populateQuiz = props.quiz.map((quiz, index) => {
     
     
     
                return    <Questions  
                key={index}
                 question={quiz.question} 
                 allAnswers={quiz.allAnswers} 
                 
                 questionId={index} 
                 selectedAnswer={quiz.selectedAnswer} 
                 //selectedAnswer={quiz.selectedAnswer}
                 checked={props.checked} 
                 //Selected answer function
                 SelectAnswer={props.SelectAnswer}
                 correctAnswer={quiz.correct_answer}
                 
                 />
        })   
 
    return(
        
            <div className="quiz-container">
            {populateQuiz}
            
            <div className="check"> 
            <h3>{props.score  !== null  ? `You scored ${props.score} / ${props.quiz.length} correct answers` : ""}</h3> 
            {!props.checked ? <button className="main-button" onClick={props.check}>Check answers</button> : <button className="main-button" onClick={props.reset}>Play Again</button>}
            
            </div>
            </div>
       
    )
}