

export default function Answer(props){

const selected = ( ) =>{
    //console.log("Answer: " + props.answer + "selectedAnswer: " + props.SelectedAnswer)
    if(props.answer === props.selectedAnswer ){
        return "selected"
        }else{return "answer"}
}

const check = () => {
    
    if(props.checked && props.selectedAnswer === props.correctAnswer && props.selectedAnswer === props.answer){
        //props.handleScore()
        return "correct"
    }else if(props.checked && props.selectedAnswer !== props.correctAnswer && props.selectedAnswer === props.answer){
        return "No"
    }
    
}

const correct = () =>{
    if(props.checked && props.correctAnswer === props.answer)
    {
        return "correct"
    }
}

//console.log("Answer: " + props.answer + "selectedAnswer: " + props.SelectedAnswer)

return(
    
            <span 
            className={`${selected()} ${check()} ${correct()}`}  
            //className={props.answer === props.selectedAnswer ? "selected" : "answer"}
            onClick={()=>props.SelectAnswer(props.answer, props.questionId)}
            >{props.answer}</span>
            
)
}