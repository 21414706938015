import React from "react";
import Start from "./components/Start";
import Quiz from "./components/Quiz";

function App() {
  const [startPage, setStartPage] = React.useState(true);
  const [quizData, setQuizData] = React.useState([]);
  const [checkedAns, setCheckedAns] = React.useState(false);
  const [score, setScore] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(function () {
    fetch("https://opentdb.com/api.php?amount=5")
      .then((response) => response.json())
      .then((data) => {
        // Set quiz data with original data

        setQuizData(
          data.results.map((quiz, index) => {
            const allAnswers = [
              quiz.correct_answer,
              quiz.incorrect_answers[0],
              quiz.incorrect_answers[1],
              quiz.incorrect_answers[2],
            ];
            const filtered = allAnswers.filter((element) => {
              return element !== undefined;
            });
            const shuffled = filtered.sort(() => Math.random() - 0.5);
            return { ...quiz, id: index, allAnswers: shuffled };
          })
        );
      });
      setScore(null)
      setCheckedAns(false)
      //setStartPage(true)
      setRefresh(false);
  }, [refresh]);

  function startQuiz() {
    setStartPage((PrevStatus) => !PrevStatus);
  }

  function checkAnswers() {
    setCheckedAns((PrevChecked) => !PrevChecked);

    const keepScore = quizData.map((data) => {
      if (data.selectedAnswer === data.correct_answer) {
        return 1;
      } else {
        return 0;
      }
    });

    let count = 0;

    for (const element of keepScore) {
      if (element === 1) {
        count += 1;
      }
    }

    setScore((PrevScore) => count);
  }

  function SelectAnswer(answer, questionId) {
    setQuizData((prevQuizData) =>
      prevQuizData.map((quiz, index) => {
        if (index === questionId) {
          return { ...quiz, selectedAnswer: answer };
        } else {
          return { ...quiz };
        }
      })
    );
  }

function reset(){
  setRefresh(true);
}

  return (
    <>
      {startPage && <Start startQuiz={startQuiz}  />}
        {!startPage && <Quiz
          quiz={quizData}
          check={checkAnswers}
          checked={checkedAns}
          SelectAnswer={SelectAnswer}
          reset={reset}
          score={score}
        />
        }
    </>
  );
}

export default App;
