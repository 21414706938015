import React from 'react'


export default function Start(props){
    
    return(
    <div id="start">
        
        <div className="container">
            <h1>Quizzical</h1>
            <p>Some description if needed</p>
            <button className="main-button" onClick={props.startQuiz}>Start Quiz</button>
            </div>
            <hr />
        </div>
    )
}